import Menu from "./Menu";
import GerirAlunos from "./../Services/Gerir/GerirAlunos";
import { useState } from "react";

export default function BodyDocente() {
  const [anoLetivo, setAnoLetivo] = useState("");

  const handleAnoLetivoChange = (event) => {
    setAnoLetivo(event.target.value);
  };

  const currentYear = new Date().getFullYear();
  const options = [
    `${currentYear}-${currentYear + 1}`,
    `${currentYear - 1}-${currentYear}`,
    `${currentYear - 2}-${currentYear - 1}`,
    `${currentYear - 3}-${currentYear - 2}`,
    `${currentYear - 4}-${currentYear - 3}`,
    `${currentYear - 5}-${currentYear - 4}`,
  ].map((year) => `20${year.substring(2, 4)}-20${year.substring(7, 9)}`);

  return (
    <div>
      <Menu />
      <div className="px-4 py-2">
        <label
          className="bg-fful text-white font-bold py-2 px-4 rounded"
          htmlFor="anoLetivo"
        >
          Selecione o ano letivo:
        </label>
        <select
          id="anoLetivo"
          name="anoLetivo"
          value={anoLetivo}
          onChange={handleAnoLetivoChange}
        >
          <option value="">Selecione</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {anoLetivo && (
        <>
          <GerirAlunos key={anoLetivo} anoLetivo={anoLetivo} />
        </>
      )}
    </div>
  );
}
