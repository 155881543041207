import React, { useState } from "react";
import { FaClipboardList, FaRegSave } from "react-icons/fa";
import { Row, Form, Col } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import { useKeycloak } from "@react-keycloak/web";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

Modal.setAppElement("#root");

function AlocarNota({ alunoData }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedAlunoData, setEditedAlunoData] = useState(alunoData);
  const [trabalho, setTrabalho] = useState();
  const [errors, setErrors] = useState({
    notaFarmacia: "",
    dataNotaFarmacia: "",
    notaHospital: "",
    dataNotaHospital: "",
    notaErasmus: "",
    dataNotaErasmus: "",
    notaExameEscrito: "",
    dataNotaExameEscrito: "",
    notaTrabalho: "",
    dataNotaTrabalho: "",
    notaFinal: "",
    arguente: "",
  });

  const apiAlunos = process.env.REACT_APP_API_ALUNOS;
  const apiTrabalhos = process.env.REACT_APP_API_TRABALHOS;
  const apiLogs = process.env.REACT_APP_API_LOGS;

  const fetchTrabalhoData = () => {
    setModalIsOpen(true);
    if (editedAlunoData.idTrabalho !== null) {
      fetch(`${apiTrabalhos}/${editedAlunoData.idTrabalho}`)
        .then((response) => response.json())
        .then((data) => setTrabalho(data))
        .catch((error) => console.error("Error fetching trabalho:", error));
    }
    fetchKeycloakPresidente();
    validateAllFIelds();
  };

  const validateAllFIelds = () => {
    const isPresidenteJuriNe = keycloak.hasRealmRole("00_PresidenteJuriNe");
    if (isPresidenteJuriNe) {
      validateField("notaTrabalho", editedAlunoData.notaTrabalho);
      validateField("dataNotaTrabalho", editedAlunoData.dataNotaTrabalho);
      validateField("arguente", editedAlunoData.arguente);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedAlunoData((prevEditedAlunoData) => ({
      ...prevEditedAlunoData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    const isPresidenteJuriNe = keycloak.hasRealmRole("00_PresidenteJuriNe");
    // Replace comma with period to handle locales that use comma as decimal separator
    const normalizedValue = String(value)?.replace(",", ".") ?? "";

    switch (fieldName) {
      case "notaFarmacia":
        if (
          normalizedValue !== "Não se aplica" &&
          normalizedValue !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(normalizedValue) ||
            parseFloat(normalizedValue) > 20)
        ) {
          error =
            "A nota de farmácia só pode conter valores entre 0.0 e 20.0 ou 'Não se aplica'";
        }
        break;
      case "notaHospital":
        if (
          normalizedValue !== "Não se aplica" &&
          normalizedValue !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(normalizedValue) ||
            parseFloat(normalizedValue) > 20)
        ) {
          error =
            "A nota de hospital só pode conter valores entre 0.0 e 20.0 ou 'Não se aplica'";
        }
        break;
      case "notaErasmus":
        if (
          normalizedValue !== "Não se aplica" &&
          normalizedValue !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(normalizedValue) ||
            parseFloat(normalizedValue) > 20)
        ) {
          error =
            "A nota de erasmus só pode conter valores entre 0.0 e 20.0 ou 'Não se aplica'";
        }
        break;
      case "notaExameEscrito":
        if (
          normalizedValue !== "Não se aplica" &&
          normalizedValue !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(normalizedValue) ||
            parseFloat(normalizedValue) > 20)
        ) {
          error =
            "A nota de exame escrito só pode conter valores entre 0.0 e 20.0 ou 'Não se aplica'";
        }
        break;
      case "notaTrabalho":
        if (isPresidenteJuriNe && !value) {
          error = "Campo obrigatório.";
        } else if (
          isPresidenteJuriNe &&
          value !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(value) ||
            parseFloat(value) > 20)
        ) {
          error = "A nota de trabalho só pode conter valores entre 0.0 e 20.0";
        }
        break;

      case "notaFinal":
        if (
          value !== "" &&
          (!/^(0|[1-9]\d{0,1})(\.\d{1,3})?$/.test(value) ||
            parseFloat(value) > 20)
        ) {
          error = "A nota final só pode conter valores entre 0.0 e 20.0";
        }
        break;
      case "arguente":
        if (isPresidenteJuriNe && !value) {
          error = "Campo obrigatório.";
        } else if (
          value !== "" &&
          !/^[a-zA-Z\u00C0-\u017F\s']+$/u.test(value)
        ) {
          error = "O nome do arguente só pode conter letras.";
        }
        break;
      case "dataNotaTrabalho":
        if (isPresidenteJuriNe && !value) {
          error = "Campo obrigatório.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    sendLog();
  };

  const { keycloak } = useKeycloak();

  const showJuri =
    keycloak.hasRealmRole("00_PresidenteJuriNe") ||
    keycloak.hasRealmRole("00_Nucleo_Estagios");

  const showNucleoEstagios = keycloak.hasRealmRole("00_Nucleo_Estagios");
  const showPresidente = keycloak.hasRealmRole("00_PresidenteJuriNe");

  const fetchKeycloakPresidente = () => {
    if (keycloak.hasRealmRole("00_PresidenteJuriNe")) {
      const username = `${keycloak.tokenParsed?.given_name || ""} ${
        keycloak.tokenParsed?.family_name || ""
      }`.trim();
      setEditedAlunoData((prevState) => ({
        ...prevState,
        presidente: username, // Update the presidente field only if the user has the 00_PresidenteJuriNe role
      }));
    }
  };
  const formatDate = (date) => {
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      second = "" + d.getSeconds();

    if (day.length < 2) day = "0" + day;
    if (month.length < 2) month = "0" + month;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return (
      [day, month, year].join("-") + " " + [hour, minute, second].join(":")
    );
  };

  const updateAluno = () => {
    fetch(`${apiAlunos}/${editedAlunoData.idAluno}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedAlunoData),
    })
      .then((response) => response.json())
      .then((data) => {
        setModalIsOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sendLog = async () => {
    if (keycloak.hasRealmRole("00_PresidenteJuriNe")) {
      try {
        const response = await fetch(`${apiLogs}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            aluno: editedAlunoData,
            changes: "",
            date: formatDate(new Date()),
            idAluno: editedAlunoData.idAluno,
            numAluno: editedAlunoData.numAluno,
            author: `${keycloak.tokenParsed?.given_name || ""} ${
              keycloak.tokenParsed?.family_name || ""
            }`.trim(),
            anoLetivo: editedAlunoData.anoLetivo,
          }),
        });
        const data = await response.json();
        console.log("Log created:", data);
        updateAluno();
      } catch (error) {
        console.error("Error logging changes:", error);
      }
    } else {
      updateAluno();
    }
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Alocar Nota"
        onClick={fetchTrabalhoData}
      >
        <FaClipboardList />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{ content: { maxWidth: "0%", maxHeight: "0%" } }}
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8 w-auto min-w-custom max-h-screen overflow-y-scroll">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Alocar nota
            </h2>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-bold">Número do Aluno</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAlunoData.numAluno}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col} md={5}>
                <Form.Label className="font-bold">Nome</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAlunoData.nome}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-bold">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={editedAlunoData.email}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Tema do Trabalho</Form.Label>
                <OverlayTrigger
                  overlay={
                    <Tooltip style={{ fontSize: "1.25rem" }}>
                      {trabalho?.tema}
                    </Tooltip>
                  }
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="tema"
                    value={trabalho?.tema || ""}
                    readOnly
                    plaintext
                    className="!bg-gray-200 rounded"
                  />
                </OverlayTrigger>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Orientador do Trabalho
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="orientador"
                  value={trabalho?.orientador || ""}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Co-Orientador / Co-Tutor Erasmus
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="coorientador1"
                  value={trabalho?.coorientador1 || ""}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
            </Row>
            {showNucleoEstagios && (
              <Row className="mb-4 d-flex align-items-center">
                <Form.Group as={Col}>
                  <Form.Label className="font-bold">
                    Observações do Aluno/a
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="obs"
                    value={editedAlunoData.obs}
                    onChange={handleChange}
                    {...(!showNucleoEstagios
                      ? {
                          readOnly: true,
                          plaintext: true,
                          className:
                            "form-control-plaintext !bg-gray-200 rounded",
                        }
                      : {})}
                  />
                </Form.Group>
              </Row>
            )}

            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nota de Farmácia</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="notaFarmacia"
                  value={editedAlunoData.notaFarmacia}
                  onChange={handleChange}
                  isInvalid={!!errors.notaFarmacia}
                  isValid={!errors.notaFarmacia}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaFarmacia}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Data nota de Farmácia
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="dataNotaFarmacia"
                  value={editedAlunoData.dataNotaFarmacia}
                  onChange={handleChange}
                  isInvalid={!!errors.dataNotaFarmacia}
                  isValid={!errors.dataNotaFarmacia}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.dataNotaFarmacia}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nota de Hospital</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="notaHospital"
                  value={editedAlunoData.notaHospital}
                  onChange={handleChange}
                  isInvalid={!!errors.notaHospital}
                  isValid={!errors.notaHospital}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaHospital}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Data nota de Hospital
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="dataNotaHospital"
                  value={editedAlunoData.dataNotaHospital}
                  onChange={handleChange}
                  isInvalid={!!errors.dataNotaHospital}
                  isValid={!errors.dataNotaHospital}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.dataNotaHospital}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nota de Erasmus</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="notaErasmus"
                  value={editedAlunoData.notaErasmus}
                  onChange={handleChange}
                  isInvalid={!!errors.notaErasmus}
                  isValid={!errors.notaErasmus}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaErasmus}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Data nota de Erasmus
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="dataNotaErasmus"
                  value={editedAlunoData.dataNotaErasmus}
                  onChange={handleChange}
                  isInvalid={!!errors.dataNotaErasmus}
                  isValid={!errors.dataNotaErasmus}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.dataNotaErasmus}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Nota de Exame Escrito
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="notaExameEscrito"
                  value={editedAlunoData.notaExameEscrito}
                  onChange={handleChange}
                  isInvalid={!!errors.notaExameEscrito}
                  isValid={!errors.notaExameEscrito}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaExameEscrito}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Data nota de Exame Escrito
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="dataNotaExameEscrito"
                  value={editedAlunoData.dataNotaExameEscrito}
                  onChange={handleChange}
                  isInvalid={!!errors.dataNotaExameEscrito}
                  isValid={!errors.dataNotaExameEscrito}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.dataNotaExameEscrito}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nota de Trabalho</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="notaTrabalho"
                  value={editedAlunoData.notaTrabalho}
                  onChange={handleChange}
                  min="0"
                  max="20"
                  step="0.01"
                  isInvalid={!!errors.notaTrabalho}
                  isValid={!errors.notaTrabalho}
                  {...(!showPresidente
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaTrabalho}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Data nota de Trabalho
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="dataNotaTrabalho"
                  value={editedAlunoData.dataNotaTrabalho}
                  onChange={handleChange}
                  isInvalid={!!errors.dataNotaTrabalho}
                  isValid={!errors.dataNotaTrabalho}
                  {...(!showPresidente
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.dataNotaTrabalho}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  O trabalho está sujeito a alterações?
                </Form.Label>
                <div>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={editedAlunoData.revisaoTrabalho ? "Sim" : "Não"} // Use boolean value directly
                    name="revisaoTrabalho"
                    checked={editedAlunoData.revisaoTrabalho} // Use boolean value directly
                    onChange={() =>
                      setEditedAlunoData({
                        ...editedAlunoData,
                        revisaoTrabalho: !editedAlunoData.revisaoTrabalho, // Toggle boolean value
                      })
                    }
                    className="me-2"
                  />
                </div>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group
                as={Col}
                className="mx-auto"
                style={{ maxWidth: "200px" }}
              >
                <Form.Label className="font-bold">Nota Final</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="notaFinal"
                  value={editedAlunoData.notaFinal}
                  onChange={handleChange}
                  min="0"
                  max="20"
                  step="0.01"
                  isInvalid={!!errors.notaFinal}
                  isValid={!errors.notaFinal}
                  {...(!showNucleoEstagios
                    ? {
                        readOnly: true,
                        plaintext: true,
                        className:
                          "form-control-plaintext !bg-gray-200 rounded",
                      }
                    : {})}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.notaFinal}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Presidente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="presidente"
                  value={editedAlunoData.presidente}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.presidente}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Arguente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="arguente"
                  value={editedAlunoData.arguente}
                  onChange={handleChange}
                  isInvalid={!!errors.arguente}
                  isValid={!errors.arguente}
                />
                <Form.Control.Feedback
                  type="valid"
                  tooltip
                  style={{ display: "none" }}
                >
                  <span className="visually-hidden">Success</span>
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  {errors.arguente}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4 d-flex align-items-center">
              <div className="text-sm mt-4">
                Nota da UC de Estágio do MICF = 15%*Nota de Farmácia + 15%*Nota
                de Hospital + 35%*Nota Exame Escrito + 35%*Nota trabalho/erasmus
              </div>
            </Row>
            <div className="py-4 flex justify-between">
              <button
                type="button"
                className="flex items-center justify-center text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                onClick={() => setModalIsOpen(false)}
                title="Cancelar"
              >
                <ImCancelCircle className="mr-2" /> <span>Cancelar</span>
              </button>
              {showJuri && (
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={`flex items-center justify-center ${
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  } mr-2`}
                  title="Guardar"
                >
                  <FaRegSave className="mr-2" /> <span>Guardar</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default AlocarNota;
