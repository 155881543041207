import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ScrollToTopButton from "../Utils/ScrollToTopButton";
import EditOrientadorHospital from "../Edit/EditOrientadorHospital";
import CreateOrientadorHospital from "../Create/CreateOrientadorHospital";
import { MdEmail } from "react-icons/md";

function GerirOrientadoresHospital({ anoLetivo }) {
  // Add anoLetivo as a prop
  const [orientadoresHospital, setOrientadoresHospital] = useState([]);
  const [selectedOrientador, setSelectedOrientador] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hospital, setHospital] = useState([]);

  const apiOrientadorHospital = process.env.REACT_APP_API_ORIENTADOR_HOSPITAL;
  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;

  useEffect(() => {
    fetch(`${apiOrientadorHospital}`)
      .then((response) => response.json())
      .then((data) => setOrientadoresHospital(data));
  }, [apiOrientadorHospital]);

  useEffect(() => {
    if (orientadoresHospital.length > 0) {
      const uniqueHospitalIds = [
        ...new Set(orientadoresHospital.map((o) => o.idHospital)),
      ];
      Promise.all(
        uniqueHospitalIds.map((id) =>
          fetch(`${apiHospitais}/${id}`).then((res) => res.json())
        )
      ).then((hospitalData) => {
        setHospital(hospitalData);
      });
    }
  }, [orientadoresHospital, apiHospitais]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchOrientadoresHospital = () => {
    fetch(`${apiOrientadorHospital}`)
      .then((response) => response.json())
      .then((data) => setOrientadoresHospital(data));
  };

  function filteredOrientadores() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = orientadoresHospital.filter((orientador) => {
      const nomeLowerCase = removeAccents(orientador.nome?.toLowerCase() ?? "");
      const emailLowerCase = removeAccents(
        orientador.email?.toLowerCase() ?? ""
      );
      const obsLowerCase = removeAccents(orientador.obs?.toLowerCase() ?? "");
      const telemovelStr = orientador.telemovel?.toString() ?? "";
      const ordemStr = orientador.numOrdem?.toString() ?? "";
      const idStr = orientador.idOrientadorH?.toString() ?? "";

      const matchesSearchTerm =
        idStr.includes(searchTerm) ||
        nomeLowerCase.includes(searchTermLowerCase) ||
        emailLowerCase.includes(searchTermLowerCase) ||
        telemovelStr.includes(searchTerm) ||
        ordemStr.includes(searchTerm) ||
        obsLowerCase.includes(searchTermLowerCase);

      return matchesSearchTerm;
    });

    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredOrientadoresArray = Array.isArray(filteredOrientadores())
    ? filteredOrientadores()
    : [];

  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (orientador) => {
    setSelectedOrientador(orientador);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiOrientadorHospital}/${selectedOrientador.idOrientadorH}`, {
      method: "DELETE",
    })
      .then((response) => {
        setShowConfirmationModal(false);
        setSelectedOrientador(null);
        fetchOrientadoresHospital();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      })
      .catch((error) => {
        console.error("Error deleting orientador: ", error);
      });
  };

  const handleCancelDelete = () => {
    setSelectedOrientador(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateOrientadorHospital anoLetivo={anoLetivo} />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar o orientador?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div></div>
        <table className="table-auto w-full" id="hospitais">
          <thead>
            <tr>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Nº de Ordem</th>
              <th className="px-4 py-2">Hospital</th>
              <th className="px-4 py-2">Observações</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrientadoresArray.map((orientador) => {
              const hospitalInfo = hospital.find(
                (h) => h.idHospital === orientador.idHospital
              );
              return (
                <tr key={orientador.idOrientadorH}>
                  <td className="border px-4 py-2">{orientador.nome}</td>
                  <td className="border px-4 py-2">{orientador.numOrdem}</td>
                  <td className="border px-4 py-2">
                    {hospitalInfo ? hospitalInfo.nome : "Loading..."}
                  </td>
                  <td className="border px-4 py-2">{orientador.obs}</td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditOrientadorHospital orientadorData={orientador} />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(orientador)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="hospitais" />
      </main>
    </div>
  );
}

export default GerirOrientadoresHospital;
