import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import Modal from "react-modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";

Modal.setAppElement("#root");

function EditOrientadorFarmacia({ orientadorData }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedOrientadorFarmacia, setEditedOrientadorFarmacia] =
    useState(orientadorData);

  const apiOrientadorFarmacia = process.env.REACT_APP_API_ORIENTADOR_FARMACIA;
  const apiFarmacias = process.env.REACT_APP_API_FARMACIAS;

  const [errors, setErrors] = useState({
    nome: "",
    email: "",
    emailAlternativo: "",
    telefone: "",
    telemovel: "",
    obs: "",
    numOrdem: "",
    idFarmacia: "",
  });

  const [farmaciaOptions, setFarmaciaOptions] = useState([]);

  useEffect(() => {
    fetch(apiFarmacias)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((farmacia) => ({
          value: farmacia.idFarmacia,
          label: farmacia.nome,
        }));
        setFarmaciaOptions(options);
      });
  }, [apiFarmacias]);

  const handleFarmaciaChange = (selectedOption) => {
    setEditedOrientadorFarmacia((prev) => ({
      ...prev,
      idFarmacia: selectedOption.value,
    }));
    validateField("idFarmacia", selectedOption.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedOrientadorFarmacia((prevEditedOrientador) => ({
      ...prevEditedOrientador,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let error = "";
    switch (fieldName) {
      case "nome":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "email":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      case "numOrdem":
        if (!value) {
          error = "Campo obrigatório";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch(
      `${apiOrientadorFarmacia}/${editedOrientadorFarmacia.idOrientadorF}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedOrientadorFarmacia),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setModalIsOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar"
        onClick={() => setModalIsOpen(true)}
      >
        <FaEdit />
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 md:p-8">
            <h2 className="text-lg font-semibold mb-4 bg-fful px-4 py-2 rounded-lg text-white">
              Editar Orientador
            </h2>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="nome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="nome"
                    onChange={handleChange}
                    value={editedOrientadorFarmacia.nome}
                    isInvalid={!!errors.nome}
                    isValid={!errors.nome}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={editedOrientadorFarmacia.email}
                    isInvalid={!!errors.email}
                    isValid={!errors.email}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="telemovel">
                  <Form.Label>Telemóvel</Form.Label>
                  <Form.Control
                    type="number"
                    name="telemovel"
                    onChange={handleChange}
                    value={editedOrientadorFarmacia.telemovel}
                    isInvalid={!!errors.telemovel}
                    isValid={!errors.telemovel}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.telemovel}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="numOrdem">
                  <Form.Label>Nº de Ordem</Form.Label>
                  <Form.Control
                    type="number"
                    name="numOrdem"
                    onChange={handleChange}
                    value={editedOrientadorFarmacia.numOrdem}
                    isInvalid={!!errors.numOrdem}
                    isValid={!errors.numOrdem}
                  />

                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.numOrdem}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="obs">
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    type="text"
                    name="obs"
                    onChange={handleChange}
                    value={editedOrientadorFarmacia.obs}
                    isInvalid={!!errors.obs}
                    isValid={!errors.obs}
                  />
                  <Form.Control.Feedback
                    type="valid"
                    tooltip
                    style={{ display: "none" }}
                  >
                    <span className="visually-hidden">Success</span>
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.obs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="idFarmacia">
                  <Form.Label>Farmácia</Form.Label>
                  <Select
                    options={farmaciaOptions}
                    onChange={handleFarmaciaChange}
                    value={farmaciaOptions.find(
                      (option) =>
                        option.value === editedOrientadorFarmacia.idFarmacia
                    )}
                    isInvalid={!!errors.idFarmacia}
                    placeholder="Selecione ou pesquise uma farmácia"
                  />
                  {errors.idFarmacia && (
                    <div className="invalid-feedback d-block">
                      {errors.idFarmacia}
                    </div>
                  )}
                </Form.Group>
              </Row>
              <div className="py-4">
                <button
                  type="submit"
                  disabled={Object.values(errors).some((value) => value !== "")}
                  className={
                    Object.values(errors).some((value) => value !== "")
                      ? "text-white px-4 py-2 rounded-md bg-gray-400 cursor-not-allowed"
                      : "text-white px-4 py-2 rounded-md bg-fful hover:bg-iconhover"
                  }
                >
                  <FaRegSave />
                </button>
                <button
                  type="button"
                  className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
                  onClick={() => setModalIsOpen(false)}
                >
                  <ImCancelCircle />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditOrientadorFarmacia;
